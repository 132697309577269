























import Vue from 'vue';
import { mapState } from 'vuex';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

interface CData {
  otp: string | null;
  isLoading: boolean;
}

interface CMethods {
  generateOTP: () => void;
}

interface CComputed {
  username: string | null;
}

export default Vue.extend<CData, CMethods, CComputed>({
  name: 'admin',
  data: () => ({
    otp: null,
    isLoading: false,
  }),
  methods: {
    async generateOTP() {
      this.isLoading = true;
      this.otp = (await this.$store.state.client.service('admin-func').get('otp')).otp;
      this.isLoading = false;
    },
  },
  computed: mapState(['username']),
  mounted() {
    if (this.username !== 'admin') {
      this.$router.push('/');
    }
  },
  components: {
    LoadingSpinner,
  },
});
